<template>
  <div class="notice">
        <top-bar :title="'问题列表'" :left="true"></top-bar>
        <div class="filterBar">
            <van-row>
                <van-col span="8">
                    <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#FE5E5E': '' }">提问时间</p>
                </van-col>
                <van-col span="8">
                    <p @click="typeShow = !typeShow" :style="{color:typeShow?'#FE5E5E': '' }">问题类型</p>
                </van-col>
                <van-col span="8">
                    <p @click="moreShow = !moreShow">更多<van-icon name="ellipsis" size="20"/></p>
                </van-col>
            </van-row>
        </div>
        <div class="dateBar" v-show="dateBarShow">
            <van-row>
                <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
                <van-col span="2" ><span>-</span></van-col>
                <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
                <van-col span="3" @click="dateClose"><span>重置</span></van-col>
                <van-col span="3" @click="changeDate"><span>确定</span></van-col>
            </van-row>
        </div>
        <div class="addBtn" @click="goAdd">
            <img src="@/assets/img/add.png" alt="">
        </div>
        <van-popup v-model="beginDateShow" position="bottom" :style="{ height: '40%' }" >
            <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="date" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
        </van-popup>
        <van-popup v-model="endDateShow" position="bottom" :style="{ height: '40%' }" >
            <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
        </van-popup>
        <van-popup v-model="typeShow" position="bottom" :style="{ height: '40%' }" >
            <van-picker title="标题" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
        </van-popup>
        <van-popup v-model="moreShow" position="right" :style="{ width: '90%', height: '100%'}" @open="moreClose">
            <div style="margin-bottom: 50px;">
                <van-cell title="问题类型">
                    <span style="color: #FE5E5E">{{moreTypeValue}}</span>
                </van-cell>
                <van-grid  :border="false" :column-num="3">
                    <van-grid-item v-for="value in typeList" :key="value.value">
                        <van-tag  size="large" @click="typeTagFn(value)" :color="value.label === moreTypeValue? '#ffe1e1':'#f0f2f5'" :text-color="value.label === moreTypeValue?'#FE5E5E':'#000'">{{value.label}}</van-tag>
                    </van-grid-item>
                </van-grid>
                <van-cell title="处理状态">
                    <span style="color: #FE5E5E">{{moreStatusValue.label}}</span>
                </van-cell>
                <van-grid  :border="false" :column-num="3">
                    <van-grid-item v-for="item in statusList" :key="item.value">
                        <van-tag  size="large" @click="statusTagFn(item)" :color="item.value == moreStatusValue.value? '#ffe1e1':'#f0f2f5'" :text-color="item.value == moreStatusValue.value?'#FE5E5E':'#000'">{{item.label}}</van-tag>
                    </van-grid-item>
                </van-grid>
            </div>
            <van-row style="position: absolute; bottom: 0; left: 0; width: 100%">
                <van-col span="12"><van-button block @click="moreCancel">重置</van-button></van-col>
                <van-col span="12"><van-button type="danger" block @click="moreConfirm">确定</van-button></van-col>
            </van-row>
        </van-popup>
        <div class="cont">
          <div v-if="dataList.length > 0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"  offset="10">
              <div class="totalCount">共有&nbsp;{{totalCount}}&nbsp;条数据</div>
              <van-cell v-for="item in dataList" :key="item.id"  @click="getInfo(item.id,item.status,item.streetIsOpen)">
                <div class="item">
                  <van-cell-group :border="false">
                    <van-cell :title="item.name + ' | ' + (item.createDate.split(' ')[0])"  class="title" center>
                      <span :style="{color:item.status==10?'#FE5E5E':'#3E7EFE'}">{{item.statusStr}}</span>
                    </van-cell>
                    <van-cell class="itemCont" :title="item.houseFullName"  :label="item.content" is-link center icon-prefix="widthImg" :icon="item.url?item.url:require('@/assets/img/flower.png')">
                      <template #title>
                        <span class="custom-title contTitle">{{item.houseFullName}} <span>({{item.typeStr}})</span></span>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </van-cell>
            </van-list>
          </div>
          <van-empty description="暂无数据" v-else />
        </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations, mapState } from 'vuex'
export default {
    components :{
        topBar
    },
     data() {
        return {
        loading: false,
        finished: false,
        endDateShow: false,
        beginDateShow: false,
        dateBarShow: false,
        typeShow: false,
        moreShow: false,
        page: 0,
        limit: 10,
        totalCount: 0,
        currentDate: '',
        selectDate: {
            endDate: '',
            beginDate: ''
        },
        typeList : ['1','2'],
        statusList: [
            { label: '无法处理', value: '0', },
            { label: '未处理', value: '10' },
            { label: '处理中', value: '20' },
            { label: '处理完成', value: '30' }],
        moreStatusValue: { label: '', value: ''},
        currentType: '',
        moreTypeValue: '',
        moreType:'',
        dataList: []
        };
     },
  computed:{...mapState(['casualTakingPhotos'])},

    methods: {
        ...mapMutations,
      getInfo (id,status,streetIsOpen) {
        console.log(id,status,streetIsOpen)
        let data = {'id':id,'status':status,'streetIsOpen':streetIsOpen}
        this.$store.commit('setProblemData', data)
        this.$router.push('/problem-info')
      },
        getDataList () {
            this.page++
            this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/problem/list'),
                method: 'post',
                params: this.$http.adornParams({
                    page: this.page,          //当前页码
                    limit: this.limit,        //每页展示的记录数
                    name: '', // 搜索框内容,以名字作为查询条件
                    beginDate: this.selectDate.beginDate, //提问时间
                    endDate: this.selectDate.endDate,
                    type: this.currentType,          //问题类型
                    status: this.moreStatusValue.value,      //处理状态
                    solveTarget: '', //处理对象
                    orgId: this.$orgId,
                    isSsp: this.casualTakingPhotos == 1 ? 1 : '',
                    userId: this.$userId,
                })
                }).then(({data})=> {
                    if (data.code == 0) {
                        if (this.dataList.length == data.list.totalCount) {
                           this.finished = true
                       }
                        data.list.list.forEach(item=> {
                            if (item.problemFiles && item.problemFiles.length != 0) {
                            let file = item.problemFiles[0]
                            let url = file.filePath
                            if(url){
                            url = getImageStream(url)
                            item["url"] = url
                            }
                           }
                       })
                      this.totalCount = data.list.totalCount
                      this.dataList = this.dataList.concat(data.list.list)
                        // 加载状态结束
                       this.loading = false;
                        this.$toast.clear()
                    }
            })
        },
        getProblemType () {
            this.$http({
                url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
                method: 'post',
                params: this.$http.adornParams({
                orgId: this.$orgId,
                code: 'problemType'
                },false)
            }).then(({data})=> {
                if (data.code == 0) {
                  console.log(this.casualTakingPhotos)
                  if (this.casualTakingPhotos == 1) {
                    this.typeList = data.dicts.filter(item => item.parameters.value == 'casualTakingPhotos')
                  } else {
                    this.typeList = data.dicts
                  }
                  console.log(this.typeList )
                    this.typeList.unshift({label:'所有', value: ''})
                } else {
                this.$toast.fail(data.msg);
                }
            })
        },
        beginDateConfim (value) {
            let d = new Date(this.selectDate.endDate)
            if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
                return this.$toast.fail('开始时间不能比结束时间大哦')
            }
            this.selectDate.beginDate = formatterDate(value)
            this.beginDateShow = false
        },
        endDateConfim (value) {
            let d = new Date(this.selectDate.beginDate)
            if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
                return this.$toast.fail('结束时间不能比开始时间小哦')
            }
            this.selectDate.endDate = formatterDate(value)
            this.endDateShow = false
        },
        changeDate () {
            this.dataList = []
            this.page = 0
            this.getDataList()
        },
        dateClose () {
            this.selectDate = {
            endDate: '',
            beginDate: ''
            }
        },
        typeConfirm (value,index) {
            this.page = 0
            this.dataList = []
            this.currentType = value.value
            this.getDataList()
            this.typeShow = false
        },
        typeTagFn (val) {
            this.moreTypeValue = val.label
            this.moreType = val.value
        },
        moreCancel () {
            this.moreTypeValue = ''
            this.moreType = ''
            this.moreStatusValue.label = ''
            this.moreStatusValue.value = ''
        },
        moreConfirm () {
            this.dataList = []
            this.page = 0
            this.currentType = this.moreType
            this.getDataList()
            this.moreShow = false
        },
        moreClose () {
            this.typeList.forEach(item => {
                if(this.currentType === item.value) {
                    this.moreTypeValue = item.label
                }
            })
        },
        statusTagFn (item) {
            this.moreStatusValue.label = item.label
            this.moreStatusValue.value = item.value
        },
        goAdd () {
            this.$router.push('/problem-add')
        }
    },
    created () {
        this.$toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true,
        });
        this.getDataList()
        this.getProblemType()
    }
}
</script>

<style>
.van-grid-item__content {
   padding: 10px  0;
 }
</style>
<style lang="scss" scoped>
.itemCont {
    .van-cell__label {
        width: 420px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.van-tag {
    text-align: center;
    display: block;
    width: 190px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow:ellipsis;
    whitewhite-space: nowrap;
    padding: 0 10px;
}
.contTitle {
    font-size: 30px;
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
        color: #3D7EFE;
        font-size: 30px;
    }
}
.widthImg {
    height: 100%;
    img {
    width: 90px;
    height: 90px;
    }
}
.title {
    .van-cell__title {
        color: #999;
    }
    .van-cell__value {
        color: #FE5E5E;
    }
}
.notice {
    padding-top: 100px;
    .cont {
        padding:  20px;
        background-color: #f5f5f5;
        .van-cell {
            margin-top: 30px;
            .title {
                margin: 0;
            }
        }
        ul {
            width: 100%;
            height: 100%;
            li {
                background-color: #fff;
                border-radius: 10px;
                margin-top: 20px;
            }
        }
    }
}
.addBtn {
    position: fixed;
    right: 40px;
    bottom: 130px;
    z-index: 999;
    width: 160px;
    height: 160px;
    img {
        width: 100%;
    }
}
.filterBar,.dateBar {
    height: 100px;
    text-align: center;
    line-height: 100px;
    font-size: 30px;
    p {
        margin: 0;
    }
}
.dateBar {
    color: #3D7EFE;
}
.van-icon-ellipsis {
    line-height: 100px;
    vertical-align: middle;
    margin-left: 5px;
}
</style>
